import React, { useContext, useEffect, useState } from "react";
import Seo from "../components/Seo/Seo";
import styled from "styled-components";
import { Box } from "../components/Core";
import { device } from "../utils";
import { Container } from "react-bootstrap";
import GlobalContext from "../context/GlobalContext";
import SuccessAppointment from "../sections/Success/SuccessAppointment";
import API from "../api/api";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;


const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`;


const AppointmentSuccess = (props) => {

    const gContext = useContext(GlobalContext);

    useEffect(() => {
        const woId = new URLSearchParams(props.location.search).get("woId")
        if (typeof window !== 'undefined' && window) {

            const woConfirmed = JSON.parse(localStorage.getItem(`workOrderConfirmed-${woId}`))

            if (!woConfirmed) {
                API
                    .put(`/FieldServiceModule/v1.0/db/WorkOrder/${woId}`, {
                        entity: "FieldServiceModule:WorkOrder",
                        properties: {
                            ConfirmationStatus: "CONFIRMED"
                        }
                    })
                    .then((resp) => {
                        localStorage.setItem(`workOrderConfirmed-${woId}`, true);
                    })
                    .catch((error) => {
                        gContext.setAPIErrorMsg({
                            title: 'Error',
                            message: error.response ? error.response?.data.message : error
                        })
                    }
                    )
            }
        }
    }, [])

    return (
        <div>
            <Seo
                title={'YouFibre | Appointment Success'}
                description={'YouFibre | Appointment Success'}
            />
            <BoxStyled bg="#f7f8fa">
                <BoxInner className="d-flex align-items-center">
                    <Container>
                        <Box my="150px" mx="auto">
                            <FormStyled>
                                <SuccessAppointment />
                            </FormStyled>
                        </Box>
                    </Container>
                </BoxInner>
            </BoxStyled>
        </div>
    )
}

export default AppointmentSuccess